import Model from './base';

export class LandingFolder extends Model {
  resource() {
    return 'landing-folder';
  }

  defaultAttributes() {
    return {
    }
  }
}
