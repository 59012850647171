import Model from './base';

export class Filters extends Model {
  resource() {
    return 'catalog/filters';
  }
  primaryKey() {
    return 'slug';
  }
}
