import Model from './base';

export class Seo extends Model {
  resource() {
    return 'catalog/seo';
  }
}

export class SeoHeaders extends Model {
  resource() {
    return 'catalog/seo-headers';
  }
}
