<template>
  <transition name="fade">
  <div v-if="rootCategory" class="tree">
    <div class="container tree-desktop">
      <div class="close-tree">
        <a href="#" class="circle" @click.prevent="closeCat">
          <i class="svg-icon cross"></i>
        </a>
      </div>
      <div class="catalog-header">
        <div class="h1">{{ $t('catalog_wellfar') }}</div>
        <nuxt-link v-if="!isAllProdsPath" @click.native="closeCat" :to="localePath(`/${rootCategory.slug}`)">{{
            $t('show_all_products')
          }}</nuxt-link>
        <span v-else @click="closeCat">{{ $t('show_all_products') }}</span>
      </div>


      <div class="vehicle-filters">
        <div v-for="filter in filters"
            class="filter button"
            :class="{'active': activeFilter === filter.slug, 'not-active': !filter.active}"
            @click="getCategoriesFilter(filter.slug)">
          {{ getFilterNameCatalog(filter) }}
        </div>
      </div>


      <div class="square-items">
        <div class="catalog-square" v-if="rootCategory.subcategories.length > 1">
          <template v-for="sub1 in rootCategory.subcategories">
            <div class="catalog-square-item" @mouseenter="action = sub1.id" :class="{'active': action ===
            sub1.id}">
              <div class="catalog-square-item-container">
                <nuxt-link class="cat-square-item-header" @click.native="closeCat"
                           :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${activeFilter}`)">{{
                    getCategoryLocaleDataSeoFilter(sub1, activeFilter)
                  }}
                </nuxt-link>
              </div>
            </div>
          </template>

          <nuxt-link class="favourites" v-show="favouriteProductsExists" :to="localePath('/products/favourites/')"
                     :aria-label="$t('favourites')">
            <i class="svg-icon star icon-brand"></i><span @click="closeCat">{{ $t('favourites') }}</span>
          </nuxt-link>

          <div v-if="contacts" class="contacts">
            <span v-if="contacts.phone">Тел. {{ contacts.phone }}</span>
            <span v-if="contacts.email">Email: {{ contacts.email }}</span>
          </div>
        </div>
        <div class="catalog-rendered-items">
          <template v-for="sub1 in rootCategory.subcategories">
            <div class="rendered_value" v-show="action === sub1.id">

              <div class="name-category">
                <div>{{ getCategoryLocaleDataSeoFilter(sub1, activeFilter) }}</div>
                <nuxt-link class="cat-square-item-header" @click.native="closeCat"
                           :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${activeFilter}`)">{{$t('all_products')
                  }}
                </nuxt-link>
                <nuxt-link :to="localePath(`/${rootCategory.slug}/${sub1.slug}/overview/${activeFilter}`)" @click.native="closeCat">
                  {{ $t('category_view') }}
                </nuxt-link>
              </div>

              <div class="pre-items">
                <div class="items">
                  <template v-for="sub2 in sub1.subcategories">
                            <span class="catalog-square-item__link" @click="closeCat">
                              <nuxt-link
                                  :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${sub2.slug}/${activeFilter}`)">
                                {{ getCategoryLocaleDataSeoFilter(sub2, activeFilter) }}
                              </nuxt-link>
                            </span>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="makers" v-if="landingFolder[0] && Object.keys(landingFolder[0]).length">
        <div>{{ getLandingFolderName }}</div>

        <div class="makers-buttons">
          <nuxt-link @click.native="closeCat" v-for="maker in landingFolder[0].makers" class="button" :key="maker.id"
                     :to="localePath(`/${landingFolder[0].slug}/${maker.slug}`)">{{
              getMakerTitleButton(maker)
            }}
          </nuxt-link>
        </div>
      </div>
    </div>
    <TreeMobile :categories="categories" @toggle-cat="closeCat" :filters="filters" :landing-folder="landingFolder"
                :seo-headers="seoHeaders" :active-filter="activeFilter" @set-active-filter="getCategoriesFilter"/>
  </div>
  </transition>
</template>

<script>
import {Category} from '@/models/category';

import ProductsSearchInput from '@/components/catalog/products-search-input';
import {DbConfig} from "@/models/db-config";
import {SeoHeaders} from "@/models/seo";
import {Filters} from "@/models/filters";
import {LandingFolder} from "@/models/landing-folder";

export default {
  components: {
    ProductsSearchInput,
    'TreeMobile': () => import(`@/components/${process.env.VENDOR}/tree-mobile`),
  },
  data() {
    return {
      categoriesDict: {},
      action: null,
      dbConfig: {},
      activeFilter: null,
      seoHeaders: [],
      filters: [],
      landingFolderDict: {},
    }
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        Category.config({count_loading: true}).get().then((response) => this.categories = response);
      },
    },
  },
  computed: {
    vendor() {
      return process.env.VENDOR
    },
    rootCategory() {
      if (this.categories[0] && this.categories[0].subcategories && this.categories[0].subcategories.length) {
        this.action = this.categories[0].subcategories[0].id
      }
      return this.categories[0];
    },
    isAllProdsPath() {
      return this.$route.path === this.localePath('/catalog/');
    },
    favouriteProductsExists() {
      return this.$auth.loggedIn && this.$store.getters.favouriteProductsExists;
    },
    contacts() {
      return {
        'phone': this.dbConfig.company_phone,
        'email': this.dbConfig.company_email
      }
    },
    getLandingFolderName() {
      return this.landingFolder[0].page_name_catalog_translations?.find(x => x.language.slug === this.$i18n.locale).title || {}
    },
    categories() {
      return this.categoriesDict[this.activeFilter];
    },
    landingFolder() {
      return this.landingFolderDict[this.activeFilter];
    },
  },
  async fetch() {
    const cl = false;
    [this.dbConfig, this.seoHeaders, this.filters] = await Promise.all([
      DbConfig.config({count_loading: cl}).first(),
      SeoHeaders.config({count_loading: cl}).get(),
      Filters.config({count_loading: cl}).get(),
    ]);
    for (let f of this.filters) {
      this.categoriesDict[f.slug] = await Category.config({count_loading: cl}).params({filter: f.slug}).get();
    }
    for (let f of this.filters) {
      this.landingFolderDict[f.slug] = await LandingFolder.config({count_loading: cl}).params({
        category_id: this.categoriesDict[f.slug][0].subcategories[0].id,
        vehicle_type: f.value,
      }).get();
    }
    this.activeFilter = this.filters[0].slug;
    this.action = this.categories[0].subcategories[0].id;
  },
  methods: {
    async getCategoriesFilter(slug) {
      this.activeFilter = slug;
    },
    getCategoryLocaleData(category) {
      return category.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    getFilterNameCatalog(filter) {
      return filter.name_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
    },
    getCategoryLocaleDataSeoFilter(category, filter_slug) {
      const seoHeader = this.seoHeaders?.find(x => x.category.id === category.id && x.filter.slug === filter_slug) ||
          {};
      if (Object.keys(seoHeader).length) {
        return seoHeader.header_translations?.find(x => x.language.slug === this.$i18n.locale).header || {};
      } else {
        return category.category_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
      }
    },
    closeCat() {
      this.$emit('toggle-cat');
    },
    setFilter(propId, value) {
      this.closeCat();
      const queryParams = {};
      queryParams[`p${propId}`] = value;
      this.$router.push({
        path: this.localePath('/search/'),
        query: queryParams,
      });
    },
    getMakerTitleButton(maker) {
      return maker.button_translations?.find(x => x.language.slug === this.$i18n.locale).title || {};
    },
  }
}
</script>

<i18n lang="yaml">
ru:
  show_all_products: 'Показать все товары'
  all: 'Все'
  category_view: 'Обзор'
  catalog_wellfar: 'Каталог продукции WELLFAR'
  commercial_vehicle_parts: 'Детали коммерческих автомобилей'
  passenger_vehicle_parts: 'Детали легковых автомобилей'
en:
  show_all_products: 'View all products'
  all: 'All'
  category_view: 'Overview'
  catalog_wellfar: 'WELLFAR Product Catalog'
  commercial_vehicle_parts: 'Commercial vehicle parts'
  passenger_vehicle_parts: 'Passenger car parts'
</i18n>
